/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  margin-right: 0 !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.navy {
  background-color: #000080 !important;
  color: #ffffff !important;
}

.text-navy {
  color: navy;
}

.text-perso {
  color: #303066;
}

.modal-header-fixed {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}

// =================================== scrollbar ===================================
.mdc-dialog__surface::-webkit-scrollbar,
.mat-menu-panel::-webkit-scrollbar,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar,
.scroll::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.mdc-dialog__surface::-webkit-scrollbar-thumb,
.mat-menu-panel::-webkit-scrollbar-thumb,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.mdc-dialog__surface::-webkit-scrollbar-thumb:hover,
.mat-menu-panel::-webkit-scrollbar-thumb:hover,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb:hover,
.scroll::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.mdc-dialog__surface::-webkit-scrollbar-thumb:active,
.mat-menu-panel::-webkit-scrollbar-thumb:active,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb:active,
.scroll::-webkit-scrollbar-thumb:active,
body::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.mdc-dialog__surface::-webkit-scrollbar-track,
.mat-menu-panel::-webkit-scrollbar-track,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.mdc-dialog__surface::-webkit-scrollbar-track:hover,
.mdc-dialog__surface::-webkit-scrollbar-track:active,
.mat-menu-panel::-webkit-scrollbar-track:hover,
.mat-menu-panel::-webkit-scrollbar-track:active,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-track:hover,
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-track:active,
.scroll::-webkit-scrollbar-track:hover,
.scroll::-webkit-scrollbar-track:active,
body::-webkit-scrollbar-track:hover,
body::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

// =================================== animaciones ===================================

// =================================== aparece desde el centro ===================================
.centro {
  -webkit-animation: centro 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: centro 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes centro {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes centro {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

// =================================== parpadeo para errores ===================================

.flicker {
  animation-name: flicker;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

// =================================== entrada para texto desde el centro ===================================
.tracking-in-expand {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

// =================================== entrada para deslizando izquierda ===================================
.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

// =================================== slide desde top ===================================
.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.cdk-overlay-pane .mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar-track:hover,
.cdk-overlay-pane .mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.mat-mdc-tab-body-content.ng-tns-c33-4.ng-trigger.ng-trigger-translateTab {
  overflow: hidden !important;
}

.mat-mdc-tab-body-content {
  overflow: hidden;
}
